import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const BuildsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const [user] = app.store("currentUser");

    const envoirements = [
        { value: "testing", label: "Testing" },
        { value: "staging", label: "Staging" },
    ];

    if (app.userCan(["createProdBuilds"], user)) {

        envoirements.push({ value: "production", label: "Production" });

    }

    return useMemo(() => (

        <Panel
            heading={"Builds Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Environment"}
                            name='environment'
                            required={true}
                            defaultValue={form.defaults.environment}
                            options={envoirements}
                        />

                    </Col>

                    {form.values.environment &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRelationship
                                key={form.values.environment}
                                form={form}
                                label={"Pipeline"}
                                name='pipeId'
                                required={true}
                                preload={true}
                                endpoint={'Pipelines'}
                                defaultValue={form.defaults.pipeId}
                                where={{ environment: form.values.environment }}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default BuildsCreateEditForm;
